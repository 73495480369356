.sidenav {
    height: 100%;
    position: fixed;
    width: 250px;
    z-index: 1;
    top: 0;
    right: -250px;
    background-color:#0d5b85;
    transition: right .6s;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
}

.sidenav.active {
  right: 0;
}

.sidenav .closebtn {
  background-image: url('../../static/icons/close.svg');
  background-size: cover;
  position: absolute;
  top: 10px;
  right: 10px;
  margin-left: 50px;
  padding: 15px 15px;
}
.widget-draggable {
  padding: 10px 10px;
  margin: 10px 10px;
  position: relative;
  display: block;
  cursor: grab;
  width: 14.5rem;
  background-color: #063954;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
  border: 1px solid #f0f3f6;
}

  
.widget-catalog-item {
  position: relative;
  display: flex;
  width: 16.25rem;
  min-height: 100px;
}

.cancel {
  bottom: 10px;
  right: 10px;
  position: absolute
}