.delete {
    background-image: url('../../static/icons/delete.svg');
    background-size: cover;
    background-position: center;
    width: 2em;
    height: 2em;
    opacity: 0.5;
}

.delete:hover {
    cursor: pointer;
    opacity: 1;
}