.settingsButton {
    background-image: url('../../static/icons/settings.svg');
    background-size: cover;
    background-position: center;
    width: 2em;
    height: 2em;
    opacity: 0.5;
}

.settingsButton:hover {
    opacity: 1;
    cursor: pointer;
}