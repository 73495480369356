.Widget {
    width: 100%;
    height: 100%;
    color: white;
}

.Widget:hover {
    text-decoration: none;
    color: white;
}

.grey {
    background-color: #f7f6f5;
}

.white {
    background-color: white;
}

.default {
    background-color: #063954;
}

.changeBar {
    background-color: rgba(255,255,255,0.7);
    position: absolute;
    height: 100%;
    right: -4px;
    top: 4px;
}